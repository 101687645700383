@tailwind base;
@tailwind components;


.hasImage:hover section {
  background-color: rgba(5, 5, 5, 0.4);
}

.hasImage:hover button:hover {
  background: rgba(5, 5, 5, 0.45);
}

#overlay p,
i {
  opacity: 0;
}

#overlay.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}

#overlay.draggedover p,
#overlay.draggedover i {
  opacity: 1;
}

.group:hover .group-hover\:text-blue-800 {
  color: #2b6cb0;
}

[type='text']:focus{
  outline-offset: 0px;
  --tw-ring-offset-shadow:none;
}

.underline-effect:before{
  transition: 0.5s all ease;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -1px;
  opacity: 0;
  content: "";
  height: 2px;
  background-color: #111112;
}

.underline-effect:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.js-underline-indigo:before {
  transition: 0.5s all ease;
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: -1px;
  opacity: 0;
  content: "";
  height: 2px;
  background-color: #6574CD;
}

.js-underline-indigo:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
}

.content-container {
  height: calc(100vh - 180px);
}

.hover-trigger .hover-target { display: none;}
.hover-trigger:hover .hover-target {display: block;}


/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  /* border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px; */
}

.accordion__item + .accordion__item {
  /* border-top: 1px solid rgba(0, 0, 0, 0.1); */
}

.accordion__button {
  /* background-color: #f4f4f4; */
  color: #444;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  /* background-color: rgb(232, 227, 227); */
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-bottom : 3px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  /* padding: 18px; */
  padding-left: 18px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

/* -------------------------------------------------- */
/* ---------------- End accordion  ------------------ */
/* -------------------------------------------------- */


/* -------------------------------------------------- */
/* ---------------- Time line  ------------------ */
/* -------------------------------------------------- */
.feed-item::after {
  content: "";
  left: -7px;
  @apply block absolute top-0 w-3 h-3 bg-white border border-red-400 rounded-full;
}

/* -------------------------------------------------- */
/* ------------- Image Gallery Slide react  --------- */
/* -------------------------------------------------- */
.image-gallery {
  width: 100%;
  height: auto;
}


.image-gallery-slide img {
  background-color: black;
  width: 100%;
  height: 400px;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
  padding: 0,0,0,0;
}



.image-gallery-slide .image-gallery-image img {
  width: 100%;
    /* object-fit: contain; */
}


.fullscreen .image-gallery-slide img {
  max-height: 100vh;
}




@tailwind utilities;

@layer components {

  .header-link{
    @apply px-2 py-2 ml-1 text-base  font-medium transition duration-150 ease-in-out rounded-md text-gray-500;
  }

  .header-link:hover {
    @apply  bg-gray-100 text-gray-600;
  }

  .header-link:focus{
    @apply outline-none bg-gray-200;
  }

  .header-link:active {
    @apply bg-gray-200 text-gray-600
  }

  .btn-blue {
    @apply py-2 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;
  }
  .btn-black {
    @apply bg-black py-1 rounded-sm px-4 mt-3 text-white hover:bg-gray-700 hover:shadow focus:outline-none ;
  }
  .btn-black:active {
    @apply outline-none ring-0;
  }

  .input-black{
    @apply  block w-full  border-gray-300 ;
  }

  .fu-checkbox {
    @apply border-2 border-black ring-0 focus:outline-none focus:bg-black focus:ring-0 focus:ring-black focus:ring-offset-0;
  }

  .input-black:focus{
    @apply outline-none focus:ring-black focus:border-black;
  }

  input[type="checkbox"]:checked + svg {
    @apply block;
  }

  input[type="radio"] {
    @apply text-black ring-0;
  }
  input[type="radio"]:checked {
    @apply bg-black;
  }
  input[type="radio"]:active {
    @apply bg-black;
  }


  input[type="checkbox"]:checked + span {
    @apply block;
  }

  input[type="checkbox"]:checked + span {
    @apply block;
  }
  input[type="checkbox"]:checked + svg {
    @apply block;
  }
  input:checked + label {
    @apply  border-gray-400 shadow-sm  bg-blue-50
  }


}